import TableFilter from "./tableFilter";

const Contact = () => {
  return (
    <>
      <div className="content-wrapper">
        <div className="content-heading d-flex justify-content-between">
          <div>Contact Us</div>
        </div>
        <TableFilter />
      </div>
    </>
  );
};

export default Contact;
