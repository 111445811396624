import TableFilter from "./tableFilter";
import { useNavigate } from "react-router-dom";

const Product = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="content-wrapper">
        <div className="content-heading d-flex justify-content-between">
          <div>Products</div>
          <div>
            <button
              className="btn btn-labeled btn-success"
              type="button"
              style={{ fontSize: "17px" }}
              onClick={() => navigate("/product/new")}
            >
              <span className="btn-label">
                <em className="fas fa-plus"></em>
              </span>
              Add New Product
            </button>
          </div>
        </div>
        <TableFilter />
      </div>
    </>
  );
};

export default Product;
