import { Navigate, Outlet } from "react-router-dom";

function PrivateRoute({ children, ...rest }) {
  return localStorage.getItem("token") ? (
    <Navigate to="/dashboard" />
  ) : (
    <Outlet />
  );
}

export default PrivateRoute;
