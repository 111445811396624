// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// ** http Imports
import http from "../../common/http.service";

// ** fetch Contact
export const fetchContact = createAsyncThunk(
  "contact/cotact-us",
  async (payload) => {
    try {
      let page = payload || 1;
      const response = await http.get("/cotact-us?page=" + page);
      return response.data;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error?.message);
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState: {
    list: {},
    isLoader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContact.pending, (state, action) => {
      state.isLoader = true;
    });
    builder.addCase(fetchContact.fulfilled, (state, action) => {
      state.list = action.payload?.data;
      state.isLoader = false;
    });
    builder.addCase(fetchContact.rejected, (state, action) => {
      state.isLoader = false;
    });
  },
});

export default contactSlice.reducer;
