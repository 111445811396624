// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// ** http Imports
import http from "../../common/http.service";

// ** fetch Product
export const fetchProduct = createAsyncThunk(
  "product/fetchProduct",
  async (payload) => {
    try {
      let page = payload || 1;
      const response = await http.get("/product?page=" + page);
      return response.data;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error?.message);
    }
  }
);

// ** fetch Product By ID
export const fetchProductByID = createAsyncThunk(
  "product/fetchProductById",
  async (payload) => {
    try {
      const response = await http.get("/product/" + payload);
      return response.data;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error?.message);
    }
  }
);

// ** create Product
export const createProduct = createAsyncThunk(
  "product/createProduct",
  async (payload) => {
    try {
      const response = await http.post("/product", {}, payload);
      return response.data;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error?.message);
    }
  }
);

// ** update Product
export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async (payload) => {
    try {
      const response = await http.put("/product/" + payload.id, {}, payload);
      return response.data;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error?.message);
    }
  }
);

// ** Delete Product
export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (id) => {
    try {
      const response = await http.delete("/product/" + id);

      return response.data;
    } catch (error) {
      throw new Error(error?.message);
    }
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState: {
    list: {},
    isLoader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProduct.pending, (state, action) => {
      state.isLoader = true;
    });
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      state.list = action.payload?.data;
      state.isLoader = false;
    });
    builder.addCase(fetchProduct.rejected, (state, action) => {
      state.isLoader = false;
    });
    builder.addCase(fetchProductByID.pending, (state, action) => {
      state.isLoader = true;
    });
    builder.addCase(fetchProductByID.fulfilled, (state, action) => {
      state.isLoader = false;
    });
    builder.addCase(fetchProductByID.rejected, (state, action) => {
      state.isLoader = false;
    });
    builder.addCase(createProduct.pending, (state, action) => {
      state.isLoader = true;
    });
    builder.addCase(createProduct.fulfilled, (state, action) => {
      state.isLoader = false;
    });
    builder.addCase(createProduct.rejected, (state, action) => {
      state.isLoader = false;
    });
    builder.addCase(updateProduct.pending, (state, action) => {
      state.isLoader = true;
    });
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      state.isLoader = false;
    });
    builder.addCase(updateProduct.rejected, (state, action) => {
      state.isLoader = false;
    });
    builder.addCase(deleteProduct.pending, (state, action) => {
      state.isLoader = true;
    });
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.isLoader = false;
    });
    builder.addCase(deleteProduct.rejected, (state, action) => {
      state.isLoader = false;
    });
  },
});

export default productSlice.reducer;
