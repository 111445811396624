// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit";

// ** Reducers
import auth from "../store/auth/index";
import sidebarReducer from "../store/sidebar";
import contactReducer from "./contact/contactSlice";
import productReducer from "./product/productSlice";

export const store = configureStore({
  reducer: {
    auth,
    sidebarReducer,
    contact: contactReducer,
    product: productReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
