import { createSlice, createAsyncThunk, isAllOf } from "@reduxjs/toolkit";

// ** Axios Imports
import { toast } from "react-toastify";
import httpService from "../../common/http.service";

export const appLogin = createAsyncThunk("appUser/appLogin", async (params) => {
  try {
    const response = await httpService.post(
      "/auth/login",
      {},
      {
        email: params.email,
        password: params.password,
      }
    );
    if (response.data) {
      response.data.navigate = params.navigate;
    }
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const appforgetPassword = createAsyncThunk(
  "appUser/appforgetPassword",
  async (payload) => {
    try {
      const response = await httpService.post(
        "/auth/forgetpassword",
        {},
        payload
      );
      return await response.data;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const appResetPassword = createAsyncThunk(
  "appUser/appResetPassword",
  async (payload) => {
    try {
      const response = await httpService.post(
        "/auth/resetpassword",
        {},
        payload
      );
      return await response.data;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const appUserSlice = createSlice({
  name: "User",
  initialState: {
    user: "",
    isloader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isAllOf(appLogin.pending), (state, action) => {
      state.isloader = true;
    });
    builder.addMatcher(isAllOf(appLogin.fulfilled), (state, action) => {
      state.user = action.payload?.data;
      if (action?.payload?.data?.token) {
        localStorage.setItem("token", action?.payload?.data?.token);
        localStorage.setItem("user", JSON.stringify(action?.payload?.data));
        action.payload.navigate("/dashboard");
      }
      state.isloader = false;
    });
    builder.addMatcher(isAllOf(appLogin.rejected), (state, action) => {
      state.isloader = false;
    });

    builder.addMatcher(isAllOf(appforgetPassword.pending), (state, action) => {
      state.isloader = true;
    });
    builder.addMatcher(
      isAllOf(appforgetPassword.fulfilled),
      (state, action) => {
        state.isloader = false;
      }
    );
    builder.addMatcher(isAllOf(appforgetPassword.rejected), (state, action) => {
      state.isloader = false;
    });
    builder.addMatcher(isAllOf(appResetPassword.pending), (state, action) => {
      state.isloader = true;
    });
    builder.addMatcher(isAllOf(appResetPassword.fulfilled), (state, action) => {
      state.isloader = false;
    });
    builder.addMatcher(isAllOf(appResetPassword.rejected), (state, action) => {
      state.isloader = false;
    });
  },
});

export default appUserSlice.reducer;
