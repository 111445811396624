import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader";
import Paginate from "../../components/pagination/paginate";
import { deleteProduct, fetchProduct } from "../../store/product/productSlice";
import swal from "sweetalert";

function TableFilter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const blogList = useSelector((state) => state?.product?.list);
  const isLoader = useSelector((state) => state?.product?.isLoader);

  useEffect(() => {
    dispatch(fetchProduct(page));
  }, [page]);

  const handleDeleteProduct = (id) => {
    swal({
      title: "Do you want to delete this item?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteProduct(id))
          .unwrap()
          .then((data) => {
            if (data?.status) {
              dispatch(fetchProduct(page));
            }
          });
      }
    });
  };

  return (
    <>
      {isLoader && <PageLoader />}
      <div className="card dataTables_wrapper">
        <div className="card-body">
          <div className="table-responsive bootgrid">
            <table className="table" id="bootgrid-command">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Image</th>
                  <th>SKU</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              {blogList?.docs?.length > 0 &&
                blogList?.docs?.map((value, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <td>{value.title}</td>
                        <td>
                          <img
                            width={40}
                            height={40}
                            src={
                              value.image ||
                              "https://i.ibb.co/MBtjqXQ/no-avatar.gif"
                            }
                            alt="avatar"
                          />
                        </td>

                        <td>{value.sku}</td>
                        <td>{value.price}</td>

                        <td>
                          <button
                            type="button"
                            className="btn btn-sm btn-info mr-2 command-edit"
                            onClick={() => navigate(`/product/${value?._id}`)}
                          >
                            <em className="fa fa-edit fa-fw"></em>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-danger command-delete"
                            onClick={() => handleDeleteProduct(value?._id)}
                          >
                            <em className="fa fa-trash fa-fw"></em>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </table>
          </div>
          {blogList?.limit && (
            <Paginate paginate={blogList} page={page} setPage={setPage} />
          )}
        </div>
      </div>
    </>
  );
}

export default TableFilter;
