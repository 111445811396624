import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { appforgetPassword } from "../../store/auth";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import PageLoader from "../../components/PageLoader/PageLoader";

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentTime = new Date();
  const [isSuccess, setIsSuccess] = useState("");
  const isLoader = useSelector((state) => state?.auth?.isloader);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Required"),
    }),
    onSubmit: (values) => {
      setIsSuccess(false);
      dispatch(appforgetPassword(values))
        .unwrap()
        .then((resp) => {
          if (resp.status) {
            setIsSuccess(resp?.data);
          }
        })
        .catch((error) => {
          setIsSuccess("");
          // toast.error(error?.message);
        });
    },
  });

  return (
    <>
      {isLoader && <PageLoader />}

      <div className="wrapper">
        <div className="block-center mt-4 wd-xl">
          <div className="card card-flat">
            <div className="card-header text-center">
              <Link to="#">
                <img
                  className="block-center rounded"
                  src="/img/fish in flash.png"
                  alt="App Logo"
                  style={{ width: "100px", height: "45px" }}
                />
              </Link>
            </div>
            <div className="card-body ">
              <p className="text-center py-2">PASSWORD RESET</p>

              {isSuccess && (
                <div className="alert alert-success fade show" role="alert">
                  {isSuccess}
                </div>
              )}

              <form
                className="mb-3"
                id="loginForm"
                onSubmit={formik.handleSubmit}
              >
                <p className="text-center">
                  Fill with your mail to receive instructions on how to reset
                  your password.
                </p>
                <div className="form-group">
                  <div className="input-group with-focus">
                    <input
                      className="form-control border-right-0"
                      id="exampleInputEmail1"
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      placeholder="Enter email"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text text-muted bg-transparent border-left-0">
                        <em className="fa fa-envelope"></em>
                      </span>
                    </div>
                  </div>
                  <div>
                    {formik.touched.email && formik.errors.email ? (
                      <div style={{ color: "red" }}>{formik.errors.email}</div>
                    ) : null}
                  </div>
                </div>
                <button className="btn btn-danger btn-block mt-3" type="submit">
                  Reset
                </button>
              </form>
              {/* <p className="pt-3 text-center">Need to Signup?</p>
            <Link className="btn btn-block btn-secondary" to="/signup">
              Register Now
            </Link> */}
            </div>
          </div>
          <div className="p-3 text-center">
            <span className="mr-2">&copy;</span>
            <span className="mr-2">{moment(currentTime).format("YYYY")}</span>
            <span className="mr-2">-</span>
            <span>Vibrantprintshop</span>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
