import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import PageLoader from "../../components/PageLoader/PageLoader";
import { FileUpload } from "../../common/fileUpload";
import {
  createProduct,
  fetchProductByID,
  updateProduct,
} from "../../store/product/productSlice";

function AddProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isLoader = useSelector((state) => state?.product?.isLoader);

  useEffect(() => {
    if (id) {
      dispatch(fetchProductByID(id))
        .unwrap()
        .then((data) => {
          if (data?.status) {
            formik.setFieldValue("title", data?.data?.title);
            formik.setFieldValue("image", data?.data?.image);
            formik.setFieldValue("sku", data?.data?.sku);
            formik.setFieldValue("price", data?.data?.price);
            formik.setFieldValue("link", data?.data?.link);
          }
        });
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      title: "",
      sku: "",
      price: "",
      image: "",
      link: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("This field is required"),
      sku: Yup.string().required("This field is required"),
      image: Yup.string().required("This field is required"),
      price: Yup.string().required("This field is required"),
      link: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      if (id) {
        values["id"] = id;
        dispatch(updateProduct(values))
          .unwrap()
          .then((data) => {
            if (data?.status) {
              navigate("/product");
            }
          });
      } else {
        dispatch(createProduct(values))
          .unwrap()
          .then((data) => {
            if (data?.status) {
              navigate("/product");
            }
          });
      }
    },
  });

  const handalFileUpload = async (file) => {
    try {
      const { files } = file.target;
      let data = Array.from(files);
      let response = await FileUpload(data[0]);
      if (response.status) {
        return response.data;
      }
      return "";
    } catch (error) {}
  };

  return (
    <>
      {isLoader && <PageLoader />}
      <div className="content-wrapper">
        <div className="content-heading">
          <div>
            <span>
              <Link to="/product">Product</Link>
            </span>
            <span> / {id ? "Edit" : "New"}</span>
          </div>
        </div>
        <div className="card card-default">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label>Title *</label>
                    <input
                      className="form-control"
                      name="title"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <div style={{ color: "red" }}>{formik.errors.title}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>SKU *</label>
                    <input
                      className="form-control"
                      name="sku"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.sku}
                    />
                    {formik.touched.sku && formik.errors.sku ? (
                      <div style={{ color: "red" }}>{formik.errors.sku}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label>Product URL *</label>
                    <input
                      className="form-control"
                      name="link"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.link}
                    />
                    {formik.touched.link && formik.errors.link ? (
                      <div style={{ color: "red" }}>{formik.errors.link}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Price *</label>
                    <input
                      className="form-control"
                      name="price"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.price}
                    />
                    {formik.touched.price && formik.errors.price ? (
                      <div style={{ color: "red" }}>{formik.errors.price}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Image *</label>
                    <br />
                    <input
                      name="image"
                      type="file"
                      onChange={async (file) => {
                        const data = await handalFileUpload(file);
                        formik.setFieldValue("image", data);
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.image && formik.errors.image ? (
                    <div style={{ color: "red" }} className="mb-3">
                      {formik.errors.image}
                    </div>
                  ) : null}
                  {formik?.values?.image && (
                    <div className="col-md-12 mt-4 mb-4 pl-0">
                      <img
                        src={formik?.values?.image}
                        width={"100%"}
                        height={300}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <button
                  className="btn btn-labeled btn-success mb-2"
                  type="submit"
                  style={{ fontSize: "17px" }}
                >
                  <span className="btn-label">
                    <i className="fa fa-check"></i>
                  </span>
                  {id ? "Update" : "Create"}
                </button>
                <span style={{ padding: "0 10px" }}>or</span>
                <button
                  className="btn btn-labeled btn-secondary mb-2"
                  type="button"
                  style={{ fontSize: "17px" }}
                  onClick={() => {
                    formik?.resetForm();
                    navigate(`/product`);
                  }}
                >
                  <span className="btn-label">
                    <i className="fa fa-times"></i>
                  </span>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddProduct;
