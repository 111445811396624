import moment from "moment/moment";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader";
import Paginate from "../../components/pagination/paginate";
import { fetchContact } from "../../store/contact/contactSlice";

function TableFilter() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const contactList = useSelector((state) => state?.contact?.list);
  const isLoader = useSelector((state) => state?.contact?.isLoader);

  useEffect(() => {
    dispatch(fetchContact(page));
  }, [page]);

  return (
    <>
      {isLoader && <PageLoader />}
      <div className="card dataTables_wrapper">
        <div className="card-body">
          <div className="table-responsive bootgrid">
            <table className="table" id="bootgrid-command">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th>Subject</th>
                  <th>Message</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              {contactList?.docs?.length > 0 &&
                contactList?.docs?.map((value, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <td>{value.name}</td>
                        <td>{value.phone}</td>
                        <td>{value.email}</td>
                        <td>{value.subject}</td>
                        <td>{value?.message}</td>
                      </tr>
                    </tbody>
                  );
                })}
            </table>
          </div>
          {contactList?.limit && (
            <Paginate paginate={contactList} page={page} setPage={setPage} />
          )}
        </div>
      </div>
    </>
  );
}

export default TableFilter;
