function Dashboard() {
  return (
    <>
      <div className="content-wrapper">
        <div className="card dataTables_wrapper">
          <div className="card-body">
            <div className="h1">Welcome, Admin</div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
