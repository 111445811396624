import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { appResetPassword } from "../../store/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useMemo } from "react";
import { useState } from "react";
import PageLoader from "../../components/PageLoader/PageLoader";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentTime = new Date();
  const [isSuccess, setIsSuccess] = useState(false);
  let query = useQuery();
  const isLoader = useSelector((state) => state?.auth?.isloader);

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("This field is required"),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords don't match")
        .min(3, "Must be 3 characters or more")
        .required("This field is requrired"),
    }),
    onSubmit: async (values) => {
      setIsSuccess(false);
      let payload = {
        password: values.password,
        token: query?.get("token"),
      };
      dispatch(appResetPassword(payload))
        .unwrap()
        .then((resp) => {
          if (resp.status) {
            setIsSuccess(true);
          }
        })
        .catch((error) => {
          setIsSuccess(false);
        });
    },
  });

  return (
    <>
      {isLoader && <PageLoader />}

      <div className="wrapper">
        <div className="block-center mt-4 wd-xl">
          <div className="card card-flat">
            <div className="card-header text-center">
              <Link to="#">
                <img
                  className="block-center rounded"
                  src="/img/fish in flash.png"
                  alt="App Logo"
                  style={{ width: "100px", height: "45px" }}
                />
              </Link>
            </div>
            {!isSuccess && (
              <div className="card-body ">
                <p className="text-center py-2">Reset My Password</p>
                <form
                  className="mb-3"
                  id="loginForm"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="form-group">
                    <label
                      className="text-muted"
                      htmlFor="signupInputRePassword1"
                    >
                      Password
                    </label>
                    <div className="input-group with-focus">
                      <input
                        className="form-control border-right-0"
                        id="exampleInputPassword1"
                        name="password"
                        type="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        placeholder="Password"
                        autoComplete="off"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text text-muted bg-transparent border-left-0">
                          <em className="fa fa-lock"></em>
                        </span>
                      </div>
                    </div>
                    <div>
                      {formik.touched.password || formik.errors.password ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      className="text-muted"
                      htmlFor="signupInputRePassword2"
                    >
                      Retype Password
                    </label>
                    <div className="input-group with-focus">
                      <input
                        className="form-control border-right-0"
                        id="signupInputRePassword2"
                        name="passwordConfirmation"
                        type="password"
                        placeholder="Retype Password"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.passwordConfirmation}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text text-muted bg-transparent border-left-0">
                          <em className="fa fa-lock"></em>
                        </span>
                      </div>
                    </div>
                    <div>
                      {formik.touched.passwordConfirmation ||
                      formik.errors.passwordConfirmation ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.passwordConfirmation}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <button
                    className="btn btn-block btn-primary mt-3"
                    type="submit"
                  >
                    Reset
                  </button>
                </form>
              </div>
            )}
            {isSuccess && (
              <div className="card-body">
                <p className="text-center py-2"> Thank you,</p>
                <p class="text-center">
                  Your password has been reset successfully.
                </p>

                <Link className="btn btn-block btn-primary" to="/">
                  LogIn
                </Link>
              </div>
            )}
          </div>
          <div className="p-3 text-center">
            <span className="mr-2">&copy;</span>
            <span className="mr-2">{moment(currentTime).format("YYYY")}</span>
            <span className="mr-2">-</span>
            <span>Vibrantprintshop</span>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
