const Menu = [
  {
    heading: "Main Navigation",
    translate: "sidebar.heading.HEADER",
  },
  {
    name: "Dashboard",
    translate: "sidebar.nav.SUBMENU",
    path: "/dashboard",
    icon: "icon-speedometer",
  },
  // {
  //   name: "Contact Us",
  //   translate: "sidebar.nav.SUBMENU",
  //   path: "/contact",
  //   icon: "icon-people",
  // },
  {
    name: "Product",
    translate: "sidebar.nav.SUBMENU",
    path: "/product",
    icon: "icon-notebook",
  },
];

export default Menu;
